import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import SEO from '../components/seo/seo';
import Loading from '../components/loading/loading';
import GalleryStyle from '../components/styled/gallery-style';
import GalleryHeroBackground from '../images/footer-pattern-sm.png';
import Gallery from '../components/gallery/gallery';
import GET_GALLERY from '../graphql/get-gallery';

export default function Page() {
  const [ galleryPage, setGalleryPage ] = useState();
  const [ seo, setSEO ] = useState();
  const { loading, data: pageData } = useQuery(GET_GALLERY, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading && pageData) {
      setGalleryPage(pageData.pages.nodes[0]);
      setSEO(pageData.pages.edges[0].node.seo);
    }
  }, [loading, pageData, galleryPage]);

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) :(
        <>
          <SEO
            title={seo?.title || 'Gallery'}
            description={seo?.description}
          />
          <GalleryStyle>
            <div 
            className="background" 
            style={{ backgroundImage: `url(${GalleryHeroBackground})` }} 
            >
              <div className="blue-overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-12 page-title">
                      <h1>{galleryPage?.galleryacf?.pageTitle}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </GalleryStyle>
          <section className="gallery-container">
            <Gallery/>
          </section>
        </>
      )}
    </>
  );
}