import { gql } from "@apollo/client";

export const GET_GALLERY = gql`
  query GetGallery {
    pages(where: {title: "Gallery"}) {
      nodes {
        id
        slug
        status
        date
        template {
          templateName
        }
        title
        content
        galleryacf {
          backgroundImage {
            sourceUrl
          }
          pageTitle
          galleryImages {
            sourceUrl
          }
        }
      },
      edges {
        node {
          seo {
            title
            focuskw
            metaDesc
          }
        }
      }
    }
  }
`;

export default GET_GALLERY;