import React from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery'
import { useGetGallery } from './use-get-gallery-images'

import './photoswipe.css'
import './default-skin.css'

export default function GalleryImages() {
  const galleryImages = useGetGallery()

  return (
    <Gallery>
      <div className="container">
        <div className="row">
        {galleryImages && galleryImages.map((photo, index) => {
          const {
            altText,
            id,
            sourceUrl,
            caption,
            mediaDetails,
          } = photo;
          return (
            <div className="col-md-4 mb-4" key={index}>   
              <Item
                original={sourceUrl}
                thumbnail={sourceUrl}
                width={mediaDetails.width}
                height={mediaDetails.height}
                title={caption}
              >
                {({ ref, open }) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    ref={ref} 
                    onClick={open} 
                    onKeyDown={open}
                    src={sourceUrl} 
                    alt={altText} 
                    key={id} 
                  />
                )}
              </Item> 
            </div>
            )
          })}
        </div>
      </div>
    </Gallery>  
  );
}