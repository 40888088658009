import styled from 'styled-components'

const GalleryStyle = styled.div`

  .background {
    background-color: rgb(32,47,102);
    padding: inherit;
  }

  .blue-overlay {
    display: -webkit-flex; /* Safari */
    display:flex;
    background-color: rgb(18,40,76, .46);
    height: 100%;
    align-items: center;
    padding: 6rem 0 4rem;

    .page-title {
      width: 100%;
      text-align: center;
      padding: 8rem 0 2rem;
      
      
      h1 {
        font-family: 'Copperplate', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-family: var(--Copperplate);
        color: white;
      }

    } 

  }

`;

export default GalleryStyle;